import React from "react";

const About: React.FC = () => {
  return (
    <section id="about" className="flex flex-col items-start pt-10 pb-10 w-[1287px] px-[10px] mx-auto max-w-full">
      <div className="flex relative flex-col py-10 w-[1110px] max-w-full bg-red-800 px-[60px] max-md:px-5">
        <h2 className="z-0 text-6xl text-white RubikDirt uppercase rotate-[1.7347232691816786e-18rad] max-md:max-w-full max-md:text-4xl max-lg:text-center max-md:text-left">
          About brettpapa
        </h2>
        <div className="flex absolute top-2/4 z-0 flex-col max-w-full -translate-y-2/4 right-[-138px] translate-x-[0%] max-xl:mt-[26px] max-xl:items-center max-xl:static max-xl:inset-0 max-xl:translate-x-0 max-xl:translate-y-0">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e70f4640337de4987c7f2686d83269b78440e77e8151f241043ccd7e65078cec?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
            alt="about-section-image"
            className="object-contain max-w-full aspect-[0.64]  w-[285px] h-[415px]"
          />
        </div>
        <p className="z-0 mt-8 text-xl leading-7 text-white text-justify rotate-[1.7347232691816786e-18rad] pr-[70px] max-md:max-w-full max-xl:pr-0">
          Born from the grand dream of every meme coin – to become the “BIG
          BOSS” of the market, BrettPapa is the ultimate “father” of the famous
          Brett meme coin! Not just inheriting the legacy, Brett Papa is set to
          surpass Brett and claim the top spot. Follow the hilarious and
          ambitious journey of Brett Papa as he takes on the throne in this epic
          “father-son” showdown! 
          <br />
          Who says Brett is the biggest? His dad’s the
          real boss!
        </p>
      </div>
    </section>
  );
};

export default About;

import React from "react";
import TokenomicsCard from "./TokenomicsCard";

interface TokenomicsData {
  title: string;
  value: string;
  imageSrc: string;
  className?: string;
}

const tokenomicsData: TokenomicsData[] = [
  {
    title: "Supply",
    value: "$1,000,000,000",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c80ac77674e4c0a67444a06e63c39fa4fca3ebef83bf13a992f9424ff1a8c21f?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    className: "rotate-1 w-[500px] max-w-full",
  },
  {
    title: "Liquidity Burnt",
    value: "$1,000,000,000",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/7451171061057eeb00b527818a2cd03945a8aa7a4a3601ea18ca994d5463b70e?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    className: "rotate-2 w-[500px] max-w-full",
  },
  {
    title: "Tax",
    value: "0%",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/711ed4d25beb72da624ba5eb9fd7bc07caae4476c3362579061568ae18ab7be7?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    className: "-rotate-1 w-[500px] max-w-full",
  },
  {
    title: "Mint Revoked",
    value: "Zero-inflation",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/e4e3c206179266258e81d970015eb80ee5a1b181871edae20b7dcdbc946de91b?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    className: "-rotate-1 w-[500px] max-w-full",
  },
];

const Tokenomics: React.FC = () => {
  return (
    <section id="tokennomic" className="flex overflow-hidden flex-col w-[1287px] items-center pt-16 pb-44 mx-auto px-[10px] max-w-full max-md:px-5 max-md:pt-0 max-md:pb-24 max-md:max-w-full">
      <div className="flex flex-col mb-0 max-w-full w-full max-md:mb-2.5">
        <h2 className="RubikDirt self-center text-6xl text-center text-white uppercase rotate-[1.7347232691816786e-18rad] max-md:max-w-full max-md:text-4xl">
          tokenomics
        </h2>
        <div className="mt-16 max-w-full w-full flex flex-col gap-11 max-md:mt-10">
          <div className="flex gap-[125px] justify-start max-md:flex-col max-xl:flex-wrap max-xl:gap-11 max-xl:justify-center">
            {tokenomicsData.slice(0, 2).map((data, index) => (
              <TokenomicsCard key={index} {...data} />
            ))}
          </div>
          <div className="flex gap-[125px] justify-end max-md:flex-col max-xl:flex-wrap max-xl:gap-11 max-xl:justify-center">
            {tokenomicsData.slice(2, 4).map((data, index) => (
              <TokenomicsCard key={index + 2} {...data} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;

import React from "react";

interface TokenomicsCardProps {
  title: string;
  value: string;
  imageSrc: string;
  className?: string;
}

const TokenomicsCard: React.FC<TokenomicsCardProps> = ({
  title,
  value,
  imageSrc,
  className
}) => {
  return (
    <div className="flex flex-col max-md:ml-0 max-md:w-full">
      <div className={`flex relative flex-col grow justify-center px-10 max-w-[500px] py-9 leading-snug whitespace-nowrap min-h-[178px] max-md:pl-5 max-md:max-w-full ${className}`}>
        <img
          loading="lazy"
          src={imageSrc}
          alt=""
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex relative flex-col max-md:max-w-full">
          <div className="self-start text-2xl font-medium text-center rotate-[1.7347232691816786e-18rad] text-stone-500">
            {title}
          </div>
          <div className="mt-2.5 text-5xl text-black rotate-[1.7347232691816786e-18rad] max-md:max-w-full max-md:text-4xl">
            {value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenomicsCard;

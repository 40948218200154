import React from "react";

const HowToBuy: React.FC = () => {
  return (
    <section id="how-to-buy" className="flex overflow-hidden flex-col justify-center items-center px-[10px] py-16 mx-auto max-w-full w-[1286px] max-md: py-8">
      <div className="flex flex-col max-w-full w-[1129px]">
        <h2 className="text-6xl RubikDirt text-center text-white uppercase rotate-[1.7347232691816786e-18rad] max-md:max-w-full max-md:text-4xl">
          HOW TO BUY
        </h2>
        <div className="flex flex-col mt-8">
          <div className="flex w-full gap-5 max-md:max-w-full max-md:justify-center max-md:flex-wrap md:justify-around">
            <div className="flex overflow-hidden flex-col justify-center py-1 min-w-[240px] max-w-[525px] max-md:max-w-full">
              <img
                loading="lazy"
                src="/add-metamask.png"
                alt="How to buy step 1"
                className="object-contain w-full aspect-[0.98] max-md:max-w-full"
              />
            </div>
            <div className="flex overflow-hidden flex-col min-w-[240px] max-w-[525px] max-md:max-w-full">
              <img
                loading="lazy"
                src="/load-eth.png"
                alt="How to buy step 2"
                className="object-contain w-full aspect-[0.89] max-md:max-w-full"
              />
            </div>
          </div>
          <div className="flex w-full gap-5 max-md:max-w-full max-md:justify-center max-md:flex-wrap md:justify-around">
            <div className="flex grow shrink min-w-[240px] max-w-[525px]">
              <img
                loading="lazy"
                src="/buy.png"
                alt="How to buy step 2"
                className="object-contain w-full aspect-[0.89] max-md:max-w-full"
              />
            </div>
            <div className="flex grow shrink min-w-[240px] max-w-[525px]">
              <img
                loading="lazy"
                src="/add-wallet.png"
                alt="How to buy step 2"
                className="object-contain w-full aspect-[0.89] max-md:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToBuy;

import React, { useEffect, useMemo, useState } from "react";
import CircleSvg from "../assets/svgs/circle";

const ImageComparison: React.FC = () => {
  const [gameState, setGameState] = useState<"welcome" | "playing" | "done">(
    "welcome"
  );
  const [isShaking, setIsShaking] = useState(false);
  const [start, setStart] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [time, setTime] = useState("00:00:00");
  const [differences, setDifferences] = useState([
    { x: 95, y: 55, found: false },
    { x: 27, y: 46, found: false },
    { x: 94, y: 91, found: false },
    { x: 10, y: 74, found: false },
    { x: 69.7, y: 68.8, found: false },
    { x: 81, y: 33, found: false },
  ]);

  const score = useMemo(
    () => differences.filter((x) => x.found).length,
    [differences]
  );

  const handleClick = (e: any) => {
    const rect = e.currentTarget.getBoundingClientRect();

    const xPercent = ((e.clientX - rect.left) / rect.width) * 100;
    const yPercent = ((e.clientY - rect.top) / rect.height) * 100;

    let correctClick = false;

    differences.forEach((diff, index) => {
      const distance = Math.sqrt(
        (xPercent - diff.x) ** 2 + (yPercent - diff.y) ** 2
      );

      if (distance < 5) {
        if (!diff.found) {
          const newDifferences = [...differences];
          newDifferences[index].found = true;
          setDifferences(newDifferences);
        }
        correctClick = true;
      }
    });

    if (!correctClick) {
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (score === differences.length) {
      setGameState("done");
    }
  }, [score, differences]);

  useEffect(() => {
    if (gameState === "playing") {
      clearTime();
      setStart(true);
      setDifferences([
        { x: 95, y: 55, found: false },
        { x: 27, y: 46, found: false },
        { x: 94, y: 91, found: false },
        { x: 10, y: 74, found: false },
        { x: 69.7, y: 68.8, found: false },
        { x: 81, y: 33, found: false },
      ]);
    }
    if (gameState === "done") {
      setStart(false);
    }
  }, [gameState]);

  const initTime: Date = new Date();

  const showTimer = (ms: any) => {
    const milliseconds = Math.floor((ms % 1000) / 10)
      .toString()
      .padStart(2, "0");
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    setTime(minute + ":" + second + ":" + milliseconds);
  };

  const clearTime = () => {
    setTime("00:00:00");
    setCount(0);
  };

  useEffect(() => {
    if (!start) {
      return;
    }
    const id = setInterval(() => {
      const left: number = count + (new Date().getTime() - initTime.getTime());
      setCount(left);
      showTimer(left);
      if (left <= 0) {
        setTime("00:00:00:00");
        clearInterval(id);
      }
    }, 1);
    return () => clearInterval(id);
  }, [start]);

  const generateGame = () => {
    switch (gameState) {
      case "playing":
        return (
          <div
            className={`bg-black  cursor-pointer p-[16px] flex gap-[16px] aspect-[1.98] min-w-[240px] w-[1250px] max-lg:flex-wrap max-lg:p-[12px] max-lg:gap-[12px] ${
              isShaking ? "bg-red-500 shake" : ""
            }`}
          >
            <div className="relative">
              <div className="absolute left-0 bottom-0 p-[10px] w-[160px] h-[50px] flex items-center justify-center bg-[#00000080] time-counter max-lg:w-[120px] max-lg:h-[40px] max-lg:!text-[20px]">
                {time}
              </div>
              <img
                className="object-contain h-full"
                src="/game-raw.png"
                alt="game-raw"
                onClick={(e) => handleClick(e)}
              />
              {differences.map(
                (diff, index) =>
                  diff.found && (
                    <div
                      key={index}
                      className="circle"
                      style={{
                        top: `${diff.y}%`,
                        left: `${diff.x}%`,
                      }}
                    >
                      <CircleSvg />
                    </div>
                  )
              )}
            </div>
            <div className="relative">
              <img
                className="object-contain h-full"
                src="/game-edited.png"
                alt="game-edited"
                onClick={(e) => handleClick(e)}
              />
              {differences.map(
                (diff, index) =>
                  diff.found && (
                    <div
                      key={index}
                      className="circle"
                      style={{
                        top: `${diff.y}%`,
                        left: `${diff.x}%`,
                      }}
                    >
                      <CircleSvg />
                    </div>
                  )
              )}
            </div>
          </div>
        );
      case "done":
        return (
          <div className="done-state bg-black p-[16px] flex items-center justify-center gap-[16px] aspect-[1.98] min-w-[240px] w-[1250px]">
            <div className="flex gap-[30px] flex-col items-center justify-center max-lg:gap-[10px]">
              <div className="congratulations max-lg:!text-[30px]">
                Congratulations!
              </div>
              <div className="flex flex-col gap-[10px] max-lg:gap-[5px]">
                <div className="game-record max-lg:!text-[20px]">Your record:</div>
                <div className="game-time max-lg:!text-[24px]"> {time}</div>
              </div>
              <div
                className="play-again-btn cursor-pointer max-lg:!text-[30px]"
                onClick={() => setGameState("playing")}
              >
                Play Again
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="welcome-state bg-black relative p-[16px] flex gap-[16px] aspect-[1.98] min-w-[240px] w-[1250px] max-lg:flex-wrap max-lg:p-[12px] max-lg:gap-[12px]">
            <div className="backdrop-blur-sm absolute inset-0 flex items-center justify-center">
              <div
                className="start-btn  cursor-pointer max-lg:!text-[30px]"
                onClick={() => setGameState("playing")}
              >
                start
              </div>
            </div>
            <div>
              <img
                className="object-contain h-full"
                src="/game-raw.png"
                alt="game-raw"
              />
            </div>
            <div>
              <img
                className="object-contain h-full"
                src="/game-edited.png"
                alt="game-edited"
              />
            </div>
          </div>
        );
    }
  };

  return (
    <section id="mini-game" className="flex overflow-hidden flex-col items-center px-20 pt-16 pb-28 w-full bg-[#3C40B2] max-md:px-5 max-md:pb-24 max-md:max-w-full">
      <div className="flex flex-col mb-0 max-w-full w-[1250px] max-md:mb-2.5">
        <h2 className="self-center text-5xl font-extrabold text-center text-white uppercase leading-snug rotate-[3.4694465383633572e-18rad] max-md:max-w-full max-md:text-[32px] max-md:px-10">
          can you SPOT <span className="text-orange-400">6</span> differences <br /> in
          these pictures?
        </h2>
        <div className="flex gap-4 items-center mt-8 game-play">
          {generateGame()}
        </div>
      </div>
    </section>
  );
};

export default ImageComparison;

import React from "react";
import "./App.css";
// import "./index.scss";
import BrettPapa from "./pages/BrettPapa";

function App() {
  return <BrettPapa />;
}

export default App;

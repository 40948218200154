import React from "react";

const TokenInfo: React.FC = () => {
  return (
    <section className="flex overflow-hidden flex-col justify-center items-center px-16 py-12 w-full bg-[#3C40B2] max-md:px-5 max-md:max-w-full">
      <div className="flex flex-wrap gap-10 items-center max-w-full w-[1250px] justify-center">
        <div className="flex flex-col self-stretch px-6 pt-2 my-auto min-w-[240px] w-[480px] max-md:px-5 max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/93e663b9a20351f6d4f09b81bcfcf1e1a2bdadfc6f8b82cc32d807bd0ef0e9f3?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
            alt="Token information"
            className="object-contain w-full aspect-[0.94] max-md:max-w-full"
          />
        </div>
        <p className="flex-1 shrink self-stretch my-auto text-4xl text-white basis-11 capitalize leading-[50px] rotate-[1.7347232691816786e-18rad] max-md:max-w-full max-md:text-[24px] max-md:leading-normal max-md:text-center">
          Who says Brett is the biggest? <br />
          His dad’s the real boss!
        </p>
      </div>
    </section>
  );
};

export default TokenInfo;

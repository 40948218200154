import React from "react";
import Header from "./Header";
import About from "./About";
import HowToBuy from "./HowToBuy";
import Tokenomics from "./Tokenomics";
import TokenInfo from "./TokenInfo";
import ImageComparison from "./ImageComparison";

const BrettPapa: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col bg-[#3C40B2]">
      <Header />
      <About />
      <HowToBuy />
      <Tokenomics />
      <ImageComparison />
      <TokenInfo />
    </div>
  );
};

export default BrettPapa;
